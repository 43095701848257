const globalConstants = {
    computed: {
        mobilePrefix(){
            return '+255 ';
        },
        genderOptions(){
            return ['Male','Female'];
        },
        maritalOptions(){
            return ['Single','Married','Widow','Widower'];
        }
    },
  };
  export default globalConstants;