import Vue from 'vue'
import App from './App.vue'
import router from './router'
import store from './store'
import vuetify from './plugins/vuetify'
import generalMethods from '@/mixins/generalMethods';
import inputRules from '@/mixins/inputRules';
import globalConstants from '@/mixins/globalConstants';
import pagninate from './components/pagninate.vue';
import search_autocomplete from './components/search_autocomplete.vue';
import VueSignaturePad from 'vue-signature-pad';

Vue.use(VueSignaturePad);
Vue.mixin(generalMethods);
Vue.mixin(inputRules);
Vue.mixin(globalConstants);
Vue.component('pagninate', pagninate);
Vue.component('searchAutocomplete', search_autocomplete);
Vue.config.productionTip = false

new Vue({
  router,
  store,
  vuetify,
  render: function (h) { return h(App) }
}).$mount('#app')
