import axios from "axios";
import router from "@/router";
import generalMethods from "@/mixins/generalMethods";

const state = {
  deductions: {
    current_page: "",
    data: [],
    first_page_url: "",
    from: 0,
    last_page: 0,
    last_page_url: "",
    links: [{ url: "", label: "", active: false }],
    next_page_url: "",
    path: "",
    per_page: 0,
    prev_page_url: "",
    to: 0,
    total: 0,
  },
  searchResults: [],
  selectedSearchResult: {},
  fixedDeductionsForPayment: []
};
const getters = {
  fixedDeductionsForPayment(state) { return state.fixedDeductionsForPayment; },
  deductionsPerPage(state) {
    return state.deductions.per_page;
  },
  allDeductions(state) {
    return state.deductions.data;
  },
  selectedSearchResult(state) {
    return state.selectedSearchResult;
  }
};
const actions = {
  async setDeduction({ commit, rootGetters, dispatch }, obj) {
    commit("loading/loading", true, { root: true });
    await axios
      .post(
        process.env.VUE_APP_API + "deduction/setDeduction",
        {
          ...obj.deduction
        },
        {
          headers: {
            Authorization: "Bearer " + rootGetters["auth/token"],
            "x-user-id": rootGetters["auth/user"].id,
          },
        }
      )
      .then((response) => {
        obj.close();
        generalMethods.methods.executeAlert({
          icon: 'success',
          title: 'Success',
          text: response.data.message
        });
        dispatch("fetchItems");
      })
      .catch((error) => {
        dispatch('auth/handleErrors', error, { root: true });
      })
      .finally(() => {
        commit("loading/loading", false, { root: true });

      });
  },

  async fetchItems(
    { commit, rootGetters, dispatch },
    obj = { search: "", perPage: "", pageNumber: "1" }
  ) {
    commit("loading/loading", true, { root: true });
    await axios
      .get(process.env.VUE_APP_API + "deduction/getDeductions", {
        params: {
          page: obj.pageNumber,
          perPage: obj.perPage,
          search: obj.search,
        },
        headers: {
          Authorization: "Bearer " + rootGetters["auth/token"],
          "x-user-id": rootGetters["auth/user"].id,
        },
      })
      .then((response) => {
        commit("setDeductions", response.data);
      })
      .catch((error) => {
        dispatch('auth/handleErrors', error, { root: true });

      })
      .finally(() => {
        commit("loading/loading", false, { root: true });

      });
  },
  async updateDeduction({ commit, rootGetters, dispatch }, obj) {
    commit("loading/loading", true, { root: true });
    await axios
      .post(
        process.env.VUE_APP_API + "deduction/updateDeduction",
        {
          ...obj.deduction
        },
        {
          headers: {
            Authorization: "Bearer " + rootGetters["auth/token"],
            "x-user-id": rootGetters["auth/user"].id,
          },
        }
      )
      .then((response) => {
        obj.close();
        generalMethods.methods.executeAlert({
          icon: 'success',
          title: 'Success',
          text: response.data.message
        });
        dispatch("fetchItems");
      })
      .catch((error) => {
        dispatch('auth/handleErrors', error, { root: true });

      })
      .finally(() => {
        commit("loading/loading", false, { root: true });

      });
  },
  async deleteDeduction({ commit, rootGetters, dispatch }, obj) {
    commit("loading/loading", true, { root: true });
    await axios
      .delete(process.env.VUE_APP_API + "deduction/deleteDeduction/" + obj.id, {
        headers: {
          Authorization: "Bearer " + rootGetters["auth/token"],
          "x-user-id": rootGetters["auth/user"].id,
        },
      })
      .then((response) => {
        commit("deleteDeduction", obj.id);
        generalMethods.methods.executeAlert({
          icon: 'success',
          title: 'Success',
          text: response.data.message
        });
        obj.close();
      })
      .catch((error) => {
        dispatch('auth/handleErrors', error, { root: true });
      })
      .finally(() => {
        commit("loading/loading", false, { root: true });

      });
  },
  async search({ commit, rootGetters, dispatch }, query) {
    commit("loading/loading2", true, { root: true });
    await axios
      .get(process.env.VUE_APP_API + "deduction/search", {
        params: {
          query: query,
        },
        headers: {
          Authorization: "Bearer " + rootGetters["auth/token"],
          "x-user-id": rootGetters["auth/user"].id,
        },
      })
      .then((response) => {
        commit("setSearchResults", response.data);
      })
      .catch((error) => {
        dispatch('auth/handleErrors', error, { root: true });
      })
      .finally(() => {
        commit("loading/loading2", false, { root: true });
      });
  },
  async getDeductionsForPayment(
    { commit, rootGetters, dispatch }
  ) {
    commit("loading/loading", true, { root: true });
    await axios
      .get(process.env.VUE_APP_API + "deduction/getDeductionsForPayment", {
        params: {
          user_id: rootGetters["salaries/salaryForm"].selectedStaff.id
        },
        headers: {
          Authorization: "Bearer " + rootGetters["auth/token"],
          "x-user-id": rootGetters["auth/user"].id,
        },
      })
      .then((response) => {
        commit("setFixedDeductionsForPayment", response.data);
      })
      .catch((error) => {
        dispatch('auth/handleErrors', error, { root: true });

      })
      .finally(() => {
        commit("loading/loading", false, { root: true });

      });
  },
  async reGetDeductionsForPayment(
    { commit, rootGetters, dispatch }
  ) {
    commit("loading/loading", true, { root: true });
    await axios
      .get(process.env.VUE_APP_API + "deduction/getDeductionsForPayment", {
        params: {
          user_id: rootGetters["salaries/salaryForm"].selectedStaff.id
        },
        headers: {
          Authorization: "Bearer " + rootGetters["auth/token"],
          "x-user-id": rootGetters["auth/user"].id,
        },
      })
      .then((response) => {
        commit("resetFixedDeductionsForPayment", response.data);
      })
      .catch((error) => {
        dispatch('auth/handleErrors', error, { root: true });

      })
      .finally(() => {
        commit("loading/loading", false, { root: true });

      });
  },
};
const mutations = {

  setFixedDeductionsForPayment(state, payload) {
    state.fixedDeductionsForPayment = payload;
  },
  setDeductions(state, Deductions) {
    state.deductions = Deductions;
  },
  deleteDeduction(state, id) {
    state.deductions.data = state.deductions.data.filter((deduction) => deduction.id !== id);
  },
  setSearchResults(state, suggestions) {
    state.searchResults = suggestions;
  },
  // setters
  setCurrentPage(state, page) {
    state.orders.current_page = page;
  },
  setPerPage(state, perPage) {
    state.orders.per_page = perPage;
  },
  selectedSearchResult(state, obj) {
    state.selectedSearchResult = obj;
  },
  resetFixedDeductionsForPayment(state, payload) {
    const newItems = payload.filter(item => !state.fixedDeductionsForPayment.some(existingItem => existingItem.id === item.id));
    state.fixedDeductionsForPayment = state.fixedDeductionsForPayment.concat(newItems);
  },
  deleteFixedDeduction(state, deduction) {
    state.fixedDeductionsForPayment  = state.fixedDeductionsForPayment.filter((fixedDeductionsForPayment) => fixedDeductionsForPayment.id !== deduction.id);
  }
};

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations,
};
