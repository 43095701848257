import axios from "axios";
import router from "@/router";
import generalMethods from "@/mixins/generalMethods";
const state = {
  user: {
    id: "",
    name: "",
    email: "",
    email_verified_at: "",
    createdby: "",
    status: "",
    remember_token: "",
    created_at: "",
    updated_at: "",
  },
  token: "",
  authenticated: false,
};
const getters = {
  user(state) {
    return state.user;
  },
  userName(state) {
    return state.user.name;
  },
  authenticated(state) {
    return state.authenticated;
  },

  token(state) {
    return state.token;
  },
};
const actions = {
  async signin({ commit, dispatch }, user) {
    commit("loading/loading", true, { root: true });
    await axios
      .post(process.env.VUE_APP_API + "signin", {
        username: user.username,
        password: user.password,
      })
      .then((response) => {
        commit("setUser", response.data);
        commit("setAuthenticated", true);
        router.push("/users");
        generalMethods.methods.executeAlert({
          icon: 'success',
          title: 'Success',
          text: response.data.message
        });
      })
      .catch((error) => {
        dispatch('handleErrors', error);
      })
      .finally(() => {
        commit("loading/loading", false, { root: true });

      });
  },
  signout({ commit, getters, dispatch, rootState }) {
    commit("loading/loading", true, { root: true });
    axios
      .get(process.env.VUE_APP_API + "signout", {
        headers: {
          Authorization: "Bearer " + getters.token,
          "x-user-id": getters.user.id,
        },
      })
      .then((response) => {
        commit("clearState", rootState);
        router.push("/");
        generalMethods.methods.executeAlert({
          icon: 'success',
          title: 'Success',
          text: response.data.message
        });
      })
      .catch((error) => {
        dispatch('handleErrors', error);
      })
      .finally(() => {
        commit("loading/loading", false, { root: true });
      });
  },
  handleErrors({ commit,dispatch }, error) {
    console.error(error);
    if (error.response && error.response.data && "error" in error.response.data) {
      generalMethods.methods.executeAlert({
        icon: 'error',
        title: 'Error',
        text: error.response.data.error,
      });
    } else {
      generalMethods.methods.executeAlert({
        icon: 'error',
        title: 'Error',
        text: "Something went wrong",
      });
    }

    if (error.response && 'exit' in error.response.data && error.response.data.exit == 1) {
      dispatch('auth/signout');
    }

    if (error.response && (error.response.status === 401 || error.response.status === 403) && !('exit' in error.response.data)) {
      dispatch('forbiddenError');
    }
    
    commit("loading/loading", false, { root: true });

  },
  forbiddenError({ commit, rootState }) {
    commit("setAuthenticated", false);
    router.push("/");
    commit("clearState", rootState);
    generalMethods.methods.executeAlert({
      icon: 'error',
      title: 'Error',
      text: "Something went wrong re-authenticate"
    });
  }
};
const mutations = {
  setUser(state, data) {
    state.user = data.user;
    state.token = data.token;
  },
  setAuthenticated(state, value) {
    state.authenticated = value;
  },

  clearState(state, rootState) {
    // Clear user module rootStates
    state.authenticated = false;
    state.user = {
      id: "",
      name: "",
      email: "",
      email_verified_at: "",
      createdby: "",
      status: "",
      remember_token: "",
      created_at: "",
      updated_at: "",
    };
    state.token = null;
    // Clear auth module rootStates
    state.user = {
      id: "",
      name: "",
      email: "",
      email_verified_at: "",
      createdby: "",
      status: "",
      remember_token: "",
      created_at: "",
      updated_at: "",
    };

    // Clear users module rootStates
    rootState["users"]["users"] = {
      current_page: "",
      data: [],
      first_page_url: "",
      from: 0,
      last_page: 0,
      last_page_url: "",
      links: [{ url: "", label: "", active: false }],
      next_page_url: "",
      path: "",
      per_page: 0,
      prev_page_url: "",
      to: 0,
      total: 0,
    };

    rootState["users"]["searchResults"] = [];
    rootState["users"]["selectedSearchResult"] = {};

    // Clear customers deductions rootStates
    rootState["deductions"]["miscellaneousSalaryDeductions"] = {
      current_page: "",
      data: [],
      first_page_url: "",
      from: 0,
      last_page: 0,
      last_page_url: "",
      links: [{ url: "", label: "", active: false }],
      next_page_url: "",
      path: "",
      per_page: 0,
      prev_page_url: "",
      to: 0,
      total: 0,
    };

    rootState["deductions"]["searchResults"] = [];
    rootState["deductions"]["selectedSearchResult"] = {};

    // Clear miscellaneousSalaryDeductions module rootStates
    rootState["miscDeductions"]["miscDeductions"] = {
      current_page: "",
      data: [],
      first_page_url: "",
      from: 0,
      last_page: 0,
      last_page_url: "",
      links: [{ url: "", label: "", active: false }],
      next_page_url: "",
      path: "",
      per_page: 0,
      prev_page_url: "",
      to: 0,
      total: 0,
    };

    rootState["miscDeductions"]["searchResults"] = [];
    rootState["miscDeductions"]["selectedSearchResult"] = {};

    // Clear roles module rootStates
    rootState["roles"]["roles"] = {
      current_page: "",
      data: [],
      first_page_url: "",
      from: 0,
      last_page: 0,
      last_page_url: "",
      links: [{ url: "", label: "", active: false }],
      next_page_url: "",
      path: "",
      per_page: 0,
      prev_page_url: "",
      to: 0,
      total: 0,
    };

    rootState["roles"]["searchResults"] = [];
    rootState["roles"]["selectedSearchResult"] = {};

    // Clear salaries module rootStates
    rootState["salaries"]["salaries"] = {
      current_page: "",
      data: [],
      first_page_url: "",
      from: 0,
      last_page: 0,
      last_page_url: "",
      links: [{ url: "", label: "", active: false }],
      next_page_url: "",
      path: "",
      per_page: 0,
      prev_page_url: "",
      to: 0,
      total: 0,
    };

    rootState["salaries"]["selectedSalary"] = {};
    rootState["salaries"]["searchResults"] = [];
    rootState["salaries"]["selectedSearchResult"] = {};


    // Clear bonuses module rootStates
    rootState["bonuses"]["bonuses"] = {
      current_page: "",
      data: [],
      first_page_url: "",
      from: 0,
      last_page: 0,
      last_page_url: "",
      links: [{ url: "", label: "", active: false }],
      next_page_url: "",
      path: "",
      per_page: 0,
      prev_page_url: "",
      to: 0,
      total: 0,
    };

    rootState["bonuses"]["searchResults"] = [];
    rootState["bonuses"]["selectedSearchResult"] = {};

  },
};

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations,
};
