import axios from "axios";
import router from "@/router";
import generalMethods from "@/mixins/generalMethods";

const state = {
  bonuses: {
    current_page: "",
    data: [],
    first_page_url: "",
    from: 0,
    last_page: 0,
    last_page_url: "",
    links: [{ url: "", label: "", active: false }],
    next_page_url: "",
    path: "",
    per_page: 0,
    prev_page_url: "",
    to: 0,
    total: 0,
  },
  searchResults: [],
  selectedSearchResult: {},
  bonusesForPayment: []
};
const getters = {
  bonusesForPayment(state) { return state.bonusesForPayment; },
  bonusesPerPage(state) {
    return state.bonuses.per_page;
  },
  allBonuses(state) {
    return state.bonuses.data;
  },
  selectedSearchResult(state) {
    return state.selectedSearchResult;
  },

};
const actions = {

  async setBonus({ commit, rootGetters, dispatch }, bonus) {
    commit("loading/loading", true, { root: true });
    await axios
      .post(
        process.env.VUE_APP_API + "bonus/setBonus",
        {
          user_id: rootGetters["salaries/selectedSalary"].user_id,
          name: bonus.name,
          description: bonus.description,
          amount: bonus.amount,
        },
        {
          headers: {
            Authorization: "Bearer " + rootGetters["auth/token"],
          "x-user-id": rootGetters["auth/user"].id,
          },
        }
      )
      .then((response) => {
        dispatch("fetchItems");
        generalMethods.methods.executeAlert({
          icon: 'success',
          title: 'Success',
          text: response.data.message
        });
      })
      .catch((error) => {
        dispatch('auth/handleErrors', error, { root: true });
      })
      .finally(() => {
        commit("loading/loading", false, { root: true });

      });
  },

  async fetchItems(
    { commit, rootGetters, dispatch },
    obj = { search: "", perPage: "", pageNumber: "1" }
  ) {
    commit("loading/loading", true, { root: true });
    await axios
      .get(process.env.VUE_APP_API + "bonus/getBonuses", {
        params: {
          user_id:rootGetters["salaries/selectedSalary"].id,
          page: obj.pageNumber,
          perPage: obj.perPage,
          search: obj.search,
        },
        headers: {
          Authorization: "Bearer " + rootGetters["auth/token"],
          "x-user-id": rootGetters["auth/user"].id,
        },
      })
      .then((response) => {
        commit("setBonuses", response.data);
      })
      .catch((error) => {
        dispatch('auth/handleErrors', error, { root: true });

      })
      .finally(() => {
        commit("loading/loading", false, { root: true });

      });
  },
  async updateBonus({ commit, rootGetters, dispatch }, bonus) {
    commit("loading/loading", true, { root: true });
    await axios
      .put(
        process.env.VUE_APP_API + "bonus/updateBonus",
        {
          id: bonus.id,
          user_id: rootGetters["salaries/selectedSalary"].user_id,
          name: bonus.name,
          description: bonus.description,
          amount: bonus.amount,
        },
        {
          headers: {
            Authorization: "Bearer " + rootGetters["auth/token"],
            "x-user-id": rootGetters["auth/user"].id,
          },
        }
      )
      .then((response) => {
        dispatch("fetchItems");

        generalMethods.methods.executeAlert({
          icon: 'success',
          title: 'Success',
          text: response.data.message
        });
      })
      .catch((error) => {
        dispatch('auth/handleErrors', error, { root: true });

      })
      .finally(() => {
        commit("loading/loading", false, { root: true });

      });
  },
  async updateBonusComplete({ commit, rootGetters, dispatch }, bonus) {
    commit("loading/loading", true, { root: true });
    await axios
      .put(
        process.env.VUE_APP_API + "bonus/updateBonusComplete",
        {
          id: bonus.id,
        },
        {
          headers: {
            Authorization: "Bearer " + rootGetters["auth/token"],
            "x-user-id": rootGetters["auth/user"].id,
          },
        }
      )
      .then((response) => {
        dispatch("fetchItems");

        generalMethods.methods.executeAlert({
          icon: 'success',
          title: 'Success',
          text: response.data.message
        });
      })
      .catch((error) => {
        dispatch('auth/handleErrors', error, { root: true });

      })
      .finally(() => {
        commit("loading/loading", false, { root: true });

      });
  },
  async deleteBonus({ commit, rootGetters, dispatch }, id) {
    commit("loading/loading", true, { root: true });
    await axios
      .delete(process.env.VUE_APP_API + "bonus/deleteBonus/" + id, {
        headers: {
          Authorization: "Bearer " + rootGetters["auth/token"],
          "x-user-id": rootGetters["auth/user"].id,
        },
      })
      .then((response) => {
        commit("deleteBonus", id);
        generalMethods.methods.executeAlert({
          icon: 'success',
          title: 'Success',
          text: response.data.message
        });
      })
      .catch((error) => {
        dispatch('auth/handleErrors', error, { root: true });
      })
      .finally(() => {
        commit("loading/loading", false, { root: true });

      });
  },
  async getBounusesForPayment(
    { commit, rootGetters, dispatch }
  ) {
    commit("loading/loading", true, { root: true });
    await axios
      .get(process.env.VUE_APP_API + "bonus/getBonusesForPayment", {
        params: {
          user_id: rootGetters["salaries/salaryForm"].selectedStaff.id
        },
        headers: {
          Authorization: "Bearer " + rootGetters["auth/token"],
          "x-user-id": rootGetters["auth/user"].id,
        },
      })
      .then((response) => {
        commit("setBonusesForPayment", response.data);
      })
      .catch((error) => {
        dispatch('auth/handleErrors', error, { root: true });

      })
      .finally(() => {
        commit("loading/loading", false, { root: true });

      });
  },
  async reGetBonusesForPayment(
    { commit, rootGetters, dispatch }
  ) {
    commit("loading/loading", true, { root: true });
    await axios
      .get(process.env.VUE_APP_API + "bonus/getBonusesForPayment", {
        params: {
          user_id: rootGetters["salaries/salaryForm"].selectedStaff.id
        },
        headers: {
          Authorization: "Bearer " + rootGetters["auth/token"],
          "x-user-id": rootGetters["auth/user"].id,
        },
      })
      .then((response) => {
        commit("resetBonusesForPayment", response.data);
      })
      .catch((error) => {
        dispatch('auth/handleErrors', error, { root: true });

      })
      .finally(() => {
        commit("loading/loading", false, { root: true });

      });
  },
};
const mutations = {
  setBonusesForPayment(state, payload) {
    state.bonusesForPayment = payload;
  },
  setBonuses(state, Bonuses) {
    state.bonuses = Bonuses;
  },
  deleteBonus(state, id) {
    state.bonuses.data = state.bonuses.data.filter((bonus) => bonus.id !== id);
  },
  setSearchResults(state, suggestions) {
    state.searchResults = suggestions;
  },
  // setters
  setCurrentPage(state, page) {
    state.bonuses.current_page = page;
  },
  setPerPage(state, perPage) {
    state.bonuses.per_page = perPage;
  },
  resetBonusesForPayment(state, payload) {
    const newItems = payload.filter(item => !state.bonusesForPayment.some(existingItem => existingItem.id === item.id));
    state.bonusesForPayment = state.bonusesForPayment.concat(newItems);
  },
  deletePaymentBonus(state, bonus) {
    state.bonusesForPayment  = state.bonusesForPayment.filter((bonusesForPayment) => bonusesForPayment.id !== bonus.id);
  }
};

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations,
};
