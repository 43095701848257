import axios from "axios";
import router from "@/router";
import generalMethods from "@/mixins/generalMethods";

const state = {
  appointments: {
    current_page: "",
    data: [],
    first_page_url: "",
    from: 0,
    last_page: 0,
    last_page_url: "",
    links: [{ url: "", label: "", active: false }],
    next_page_url: "",
    path: "",
    per_page: 0,
    prev_page_url: "",
    to: 0,
    total: 0,
  },
  searchResults: [],
  selectedSearchResult: {},
};
const getters = {
  appointmentsPerPage(state) {
    return state.appointments.per_page;
  },
  allAppointments(state) {
    return state.appointments.data;
  },
  selectedSearchResult(state) {
    return state.selectedSearchResult;
  }
};
const actions = {
  async setAppointment({ commit, rootGetters, dispatch }, obj) {
    let formData = new FormData();
    // Append appointment data to FormData dynamically
    for (const key in obj.appointment) {
      if (obj.appointment.hasOwnProperty(key)) {
        formData.append(key, obj.appointment[key]);
      }
    }

    const selectedCreditCompanyResult = rootGetters["creditCompanies/selectedSearchResult"];
    const selectedAppointmentCategoryResult = rootGetters["appointmentCategories/selectedSearchResult"];
    const appointment_category_id = selectedAppointmentCategoryResult && "id" in selectedAppointmentCategoryResult ? selectedAppointmentCategoryResult.id : '';
    const credit_company_id = selectedCreditCompanyResult && "id" in selectedCreditCompanyResult ? selectedCreditCompanyResult.id : '';

    formData.append("appointment_category_id", appointment_category_id);
    formData.append("credit_company_id", credit_company_id);

    commit("loading/loading", true, { root: true });
    await axios
      .post(
        process.env.VUE_APP_API + "appointment/setAppointment",
        formData,
        {
          headers: {
            Authorization: "Bearer " + rootGetters["auth/token"],
            "x-user-id": rootGetters["auth/user"].id,
          },
        }
      )
      .then((response) => {
        generalMethods.methods.executeAlert({
          icon: 'success',
          title: 'Success',
          text: response.data.message
        });
        obj.close();
        dispatch("fetchItems");
      })
      .catch((error) => {
        dispatch('auth/handleErrors', error, { root: true });
      })
      .finally(() => {
        commit("loading/loading", false, { root: true });

      });
  },
  async fetchItems(
    { commit, rootGetters, dispatch },
    obj = { search: "", perPage: "", pageNumber: "1" }
  ) {
    commit("loading/loading", true, { root: true });
    await axios
      .get(process.env.VUE_APP_API + "appointment/getAppointments", {
        params: {
          page: obj.pageNumber,
          perPage: obj.perPage,
          search: obj.search,
        },
        headers: {
          Authorization: "Bearer " + rootGetters["auth/token"],
          "x-user-id": rootGetters["auth/user"].id,
        },
      })
      .then((response) => {
        commit("setAppointments", response.data);
      })
      .catch((error) => {
        dispatch('auth/handleErrors', error, { root: true });

      })
      .finally(() => {
        commit("loading/loading", false, { root: true });

      });
  },
  async updateAppointment({ commit, rootGetters, dispatch }, obj) {
    commit("loading/loading", true, { root: true });
    let formData = new FormData();

    // Append appointment data to FormData dynamically
    for (const key in obj.appointment) {
      if (obj.appointment.hasOwnProperty(key)) {
        formData.append(key, obj.appointment[key]);
      }
    }

    const selectedCreditCompanyResult = rootGetters["creditCompanies/selectedSearchResult"];
    const selectedAppointmentCategoryResult = rootGetters["appointmentCategories/selectedSearchResult"];
    const appointment_category_id = selectedAppointmentCategoryResult && "id" in selectedAppointmentCategoryResult ? selectedAppointmentCategoryResult.id : '';
    const credit_company_id = selectedCreditCompanyResult && "id" in selectedCreditCompanyResult ? selectedCreditCompanyResult.id : '';

    formData.append("appointment_category_id", appointment_category_id);
    formData.append("credit_company_id", credit_company_id);

    await axios
      .post(
        process.env.VUE_APP_API + "appointment/updateAppointment",
        formData,
        {
          headers: {
            Authorization: "Bearer " + rootGetters["auth/token"],
            "x-user-id": rootGetters["auth/user"].id,
          },
        }
      )
      .then((response) => {

        generalMethods.methods.executeAlert({
          icon: 'success',
          title: 'Success',
          text: response.data.message
        });
        obj.close();
        dispatch("fetchItems");

      })
      .catch((error) => {
        dispatch('auth/handleErrors', error, { root: true });

      })
      .finally(() => {
        commit("loading/loading", false, { root: true });

      });
  },
  async deleteAppointment({ commit, rootGetters, dispatch }, obj) {
    commit("loading/loading", true, { root: true });
    await axios
      .delete(process.env.VUE_APP_API + "appointment/deleteAppointment/" + obj.id, {
        headers: {
          Authorization: "Bearer " + rootGetters["auth/token"],
          "x-user-id": rootGetters["auth/user"].id,
        },
      })
      .then((response) => {
        commit("deleteAppointment", obj.id);
        generalMethods.methods.executeAlert({
          icon: 'success',
          title: 'Success',
          text: response.data.message
        });
        obj.close();
      })
      .catch((error) => {
        dispatch('auth/handleErrors', error, { root: true });
      })
      .finally(() => {
        commit("loading/loading", false, { root: true });

      });
  },
  async search({ commit, rootGetters, dispatch }, query) {
    commit("loading/loading2", true, { root: true });
    await axios
      .get(process.env.VUE_APP_API + "appointment/search", {
        params: {
          query: query,
        },
        headers: {
          Authorization: "Bearer " + rootGetters["auth/token"],
          "x-user-id": rootGetters["auth/user"].id,
        },
      })
      .then((response) => {
        commit("setSearchResults", response.data);
      })
      .catch((error) => {
        dispatch('auth/handleErrors', error, { root: true });
      })
      .finally(() => {
        commit("loading/loading2", false, { root: true });
      });
  },
};
const mutations = {
  setAppointments(state, Appointments) {
    state.appointments = Appointments;
  },
  deleteAppointment(state, id) {
    state.appointments.data = state.appointments.data.filter((appointment) => appointment.id !== id);
  },
  setSearchResults(state, suggestions) {
    state.searchResults = suggestions;
  },
  // setters
  setCurrentPage(state, page) {
    state.appointments.current_page = page;
  },
  setPerPage(state, perPage) {
    state.appointments.per_page = perPage;
  },
  selectedSearchResult(state, obj) {
    state.selectedSearchResult = obj;
  },
};

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations,
};
