import axios from "axios";
import router from "@/router";
import generalMethods from "@/mixins/generalMethods";

const state = {
  users: {
    current_page: "",
    data: [],
    first_page_url: "",
    from: 0,
    last_page: 0,
    last_page_url: "",
    links: [{ url: "", label: "", active: false }],
    next_page_url: "",
    path: "",
    per_page: 0,
    prev_page_url: "",
    to: 0,
    total: 0,
  },
  searchResults: [],
  selectedSearchResult: {},
};
const getters = {
  usersPerPage(state) {
    return state.users.per_page;
  },
  allUsers(state) {
    return state.users.data;
  },
  selectedSearchResult(state) {
    return state.selectedSearchResult;
  }
};
const actions = {
  async setUser({ commit, rootGetters, dispatch }, obj) {
    let formData = new FormData();
    // Append user data to FormData dynamically
    for (const key in obj.user) {
      if (obj.user.hasOwnProperty(key)) {
        formData.append(key, obj.user[key]);
      }
    }
    formData.append("role_id", rootGetters["roles/selectedSearchResult"].id);
    commit("loading/loading", true, { root: true });
    await axios
      .post(
        process.env.VUE_APP_API + "user/setUser",
        formData,
        {
          headers: {
            Authorization: "Bearer " + rootGetters["auth/token"],
            "x-user-id": rootGetters["auth/user"].id,
          },
        }
      )
      .then((response) => {
        generalMethods.methods.executeAlert({
          icon: 'success',
          title: 'Success',
          text: response.data.message
        });
        obj.close();
        dispatch("fetchItems");
      })
      .catch((error) => {
        dispatch('auth/handleErrors', error, { root: true });
      })
      .finally(() => {
        commit("loading/loading", false, { root: true });

      });
  },
  async fetchItems(
    { commit, rootGetters, dispatch },
    obj = { search: "", perPage: "", pageNumber: "1" }
  ) {
    commit("loading/loading", true, { root: true });
    await axios
      .get(process.env.VUE_APP_API + "user/getUsers", {
        params: {
          page: obj.pageNumber,
          perPage: obj.perPage,
          search: obj.search,
        },
        headers: {
          Authorization: "Bearer " + rootGetters["auth/token"],
          "x-user-id": rootGetters["auth/user"].id,
        },
      })
      .then((response) => {
        commit("setUsers", response.data);
      })
      .catch((error) => {
        dispatch('auth/handleErrors', error, { root: true });

      })
      .finally(() => {
        commit("loading/loading", false, { root: true });

      });
  },
  async updateUser({ commit, rootGetters, dispatch }, obj) {
    commit("loading/loading", true, { root: true });
    let formData = new FormData();

    // Append user data to FormData dynamically
    for (const key in obj.user) {
      if (obj.user.hasOwnProperty(key)) {
        if(obj.user[key])
        formData.append(key, obj.user[key]);
      }
    }
    const selectedRoleResult = rootGetters["roles/selectedSearchResult"];
const roleId = selectedRoleResult && "id" in selectedRoleResult ? selectedRoleResult.id : '';

formData.append("role_id", roleId);

    await axios
      .post(
        process.env.VUE_APP_API + "user/updateUser",
        formData,
        {
          headers: {
            Authorization: "Bearer " + rootGetters["auth/token"],
            "x-user-id": rootGetters["auth/user"].id,
          },
        }
      )
      .then((response) => {

        generalMethods.methods.executeAlert({
          icon: 'success',
          title: 'Success',
          text: response.data.message
        });
        obj.close();
        dispatch("fetchItems");

      })
      .catch((error) => {
        dispatch('auth/handleErrors', error, { root: true });

      })
      .finally(() => {
        commit("loading/loading", false, { root: true });

      });
  },
  async deleteUser({ commit, rootGetters, dispatch }, obj) {
    commit("loading/loading", true, { root: true });
    await axios
      .delete(process.env.VUE_APP_API + "user/deleteUser/" + obj.id, {
        headers: {
          Authorization: "Bearer " + rootGetters["auth/token"],
          "x-user-id": rootGetters["auth/user"].id,
        },
      })
      .then((response) => {
        commit("deleteUser", obj.id);
        generalMethods.methods.executeAlert({
          icon: 'success',
          title: 'Success',
          text: response.data.message
        });
        obj.close();
      })
      .catch((error) => {
        dispatch('auth/handleErrors', error, { root: true });
      })
      .finally(() => {
        commit("loading/loading", false, { root: true });

      });
  },
  async search({ commit, rootGetters, dispatch }, query) {
    commit("loading/loading2", true, { root: true });
    await axios
      .get(process.env.VUE_APP_API + "user/search", {
        params: {
          query: query,
        },
        headers: {
          Authorization: "Bearer " + rootGetters["auth/token"],
          "x-user-id": rootGetters["auth/user"].id,
        },
      })
      .then((response) => {
        commit("setSearchResults", response.data);
      })
      .catch((error) => {
        dispatch('auth/handleErrors', error, { root: true });
      })
      .finally(() => {
        commit("loading/loading2", false, { root: true });
      });
  },
};
const mutations = {
  setUsers(state, Users) {
    state.users = Users;
  },
  deleteUser(state, id) {
    state.users.data = state.users.data.filter((user) => user.id !== id);
  },
  setSearchResults(state, suggestions) {
    state.searchResults = suggestions;
  },
  // setters
  setCurrentPage(state, page) {
    state.users.current_page = page;
  },
  setPerPage(state, perPage) {
    state.users.per_page = perPage;
  },
  selectedSearchResult(state, obj) {
    state.selectedSearchResult = obj;
  },
};

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations,
};
