<template>
  <v-row>
    <v-col cols="6" align-self="center">
      <v-card class="mx-auto mt-15" width="80%" height="100%" flat>
        <v-card-title class="font-weight-bold black--text">
      Rani Dental System
    </v-card-title>
    <v-card-subtitle class="overline font-weight-bold black--text">welcome</v-card-subtitle>
        <signinForm />
      </v-card>
    </v-col>
    <v-col align-self="center">
      <v-img
        src="@/assets/logo-transparent.png"
        lazy-src="@/assets/logo-transparent.png"
        width="400px"
        class="mx-auto mt-15"
      >
        <template v-slot:placeholder>
          <v-row class="fill-height ma-0" align="center" justify="center">
            <v-progress-circular
              indeterminate
              color="#56BBD8"
            ></v-progress-circular>
          </v-row>
        </template>
      </v-img>
    </v-col>
  </v-row>
</template>

<script>
import signinForm from "./signinForm.vue";
export default {
  data() {
    return {};
  },
  components: { signinForm },
};
</script>

<style scoped>
</style>