import axios from "axios";
import router from "@/router";
import generalMethods from "@/mixins/generalMethods";

const state = {
  salaries: {
    current_page: "",
    data: [],
    first_page_url: "",
    from: 0,
    last_page: 0,
    last_page_url: "",
    links: [{ url: "", label: "", active: false }],
    next_page_url: "",
    path: "",
    per_page: 0,
    prev_page_url: "",
    to: 0,
    total: 0,
  },
  salaryForm: {
    e6: 1,
    selectedStaff:{first_name:null,middle_name:null,last_name:null},
    bonusesForPayment:[],
    fixedDeductionsForPayment:[],
    miscDeductionsForPayment:[],
    remark:null,
    signature:null,
  },
  selectedSalary: {},
  searchResults: [],
  selectedSearchResult: {},
  salaryPaymentReport: {
    current_page: "",
    data: [],
    first_page_url: "",
    from: 0,
    last_page: 0,
    last_page_url: "",
    links: [{ url: "", label: "", active: false }],
    next_page_url: "",
    path: "",
    per_page: 0,
    prev_page_url: "",
    to: 0,
    total: 0,
  },
  salaryPaymentDetails:{}
};
const getters = {
  salaryForm(state) { return state.salaryForm; },
  preview(state) {
    let preview = [];
    let salaryDescription = "Staff Salary: "+state.salaryForm.selectedStaff.first_name+" "+state.salaryForm.selectedStaff.middle_name+" "+state.salaryForm.selectedStaff.last_name;
    preview.push({
      description: salaryDescription,
      in: state.salaryForm.selectedStaff.salary??0,
      out: 0
    });

    //Bonuses
    state.salaryForm.bonusesForPayment.forEach((bonus) => {
      preview.push({
        description: bonus.name,
        out: 0,
        in: bonus.payAmount??0
      });
    });

    //Deductions
    state.salaryForm.fixedDeductionsForPayment.forEach((deduction) => {
      preview.push({
        description: deduction.name,
        in: 0,
        out: deduction.payAmount??0
      });
    });

    state.salaryForm.miscDeductionsForPayment.forEach((deduction) => {
      preview.push({
        description: deduction.name,
        in: 0,
        out: deduction.payAmount??0
      });
    });
    let totalIn = 0;
    let totalOut = 0;

    preview.forEach((data) => {
      totalIn += parseFloat(data.in);
      totalOut += parseFloat(data.out);
    });
    preview.push({
      description: "Total",
      in: totalIn,
      out: totalOut
    });
    return { preview: preview, remark: state.salaryForm.remark, signature: state.salaryForm.signature };
  },
  e6(state) { return state.salaryForm.e6; },
  salariesPerPage(state) {
    return state.salaries.per_page;
  },
  allSalaries(state) {
    return state.salaries.data;
  },
  selectedSearchResult(state) {
    return state.selectedSearchResult;
  },
  selectedSalary(state) {
    return state.selectedSalary;
  },
  salaryPaymentReportPerPage(state) {
    return state.salaryPaymentReport.per_page;
  },
  allSalaryPaymentReport(state) {
    return state.salaryPaymentReport.data;
  },
  salaryPaymentDetails(state){
    return state.salaryPaymentDetails;
  }
};
const actions = {
  async redirectToMiscellaneousSalaryDeductions({ commit }, item) {
    await commit('setSelectedSalary', item);
    router.push('/miscDeductions');
  },
  async redirectToSalaryBonus({ commit }, item) {
    await commit('setSelectedSalary', item);
    router.push('/bonus');
  },
  async setSalary({ commit, rootGetters, dispatch }, salary) {
    commit("loading/loading", true, { root: true });
    await axios
      .post(
        process.env.VUE_APP_API + "salary/setSalary",
        {
          user_id: rootGetters["users/selectedSearchResult"].id,
          amount: salary.amount,
        },
        {
          headers: {
            Authorization: "Bearer " + rootGetters["auth/token"],
            "x-user-id": rootGetters["auth/user"].id,
          },
        }
      )
      .then((response) => {
        dispatch("fetchItems");
        generalMethods.methods.executeAlert({
          icon: 'success',
          title: 'Success',
          text: response.data.message
        });
      })
      .catch((error) => {
        dispatch('auth/handleErrors', error, { root: true });
      })
      .finally(() => {
        commit("loading/loading", false, { root: true });

      });
  },

  async fetchItems(
    { commit, rootGetters, dispatch },
    obj = { search: "", perPage: "", pageNumber: "1" }
  ) {
    commit("loading/loading", true, { root: true });
    await axios
      .get(process.env.VUE_APP_API + "salary/getSalaries", {
        params: {
          page: obj.pageNumber,
          perPage: obj.perPage,
          search: obj.search,
        },
        headers: {
          Authorization: "Bearer " + rootGetters["auth/token"],
          "x-user-id": rootGetters["auth/user"].id,
        },
      })
      .then((response) => {
        commit("setSalaries", response.data);
      })
      .catch((error) => {
        dispatch('auth/handleErrors', error, { root: true });

      })
      .finally(() => {
        commit("loading/loading", false, { root: true });

      });
  },
  async updateSalary({ commit, rootGetters, dispatch }, salary) {
    const selectedUserResult = rootGetters["users/selectedSearchResult"];
    const userId = selectedUserResult && "id" in selectedUserResult ? selectedUserResult.id : '';


    commit("loading/loading", true, { root: true });
    await axios
      .put(
        process.env.VUE_APP_API + "salary/updateSalary",
        {
          id: salary.id,
          user_id: userId,
          amount: salary.amount,
        },
        {
          headers: {
            Authorization: "Bearer " + rootGetters["auth/token"],
            "x-user-id": rootGetters["auth/user"].id,
          },
        }
      )
      .then((response) => {
        dispatch("fetchItems");

        generalMethods.methods.executeAlert({
          icon: 'success',
          title: 'Success',
          text: response.data.message
        });
      })
      .catch((error) => {
        dispatch('auth/handleErrors', error, { root: true });

      })
      .finally(() => {
        commit("loading/loading", false, { root: true });

      });
  },
  async deleteSalary({ commit, rootGetters, dispatch }, id) {
    commit("loading/loading", true, { root: true });
    await axios
      .delete(process.env.VUE_APP_API + "salary/deleteSalary/" + id, {
        headers: {
          Authorization: "Bearer " + rootGetters["auth/token"],
          "x-user-id": rootGetters["auth/user"].id,
        },
      })
      .then((response) => {
        commit("deleteSalary", id);
        generalMethods.methods.executeAlert({
          icon: 'success',
          title: 'Success',
          text: response.data.message
        });
      })
      .catch((error) => {
        dispatch('auth/handleErrors', error, { root: true });
      })
      .finally(() => {
        commit("loading/loading", false, { root: true });

      });
  },
  async search({ commit, rootGetters, dispatch }, query) {
    commit("loading/loading2", true, { root: true });
    await axios
      .get(process.env.VUE_APP_API + "salary/search", {
        params: {
          query: query,
        },
        headers: {
          Authorization: "Bearer " + rootGetters["auth/token"],
          "x-user-id": rootGetters["auth/user"].id,
        },
      })
      .then((response) => {
        commit("setSearchResults", response.data);
      })
      .catch((error) => {
        dispatch('auth/handleErrors', error, { root: true });
      })
      .finally(() => {
        commit("loading/loading2", false, { root: true });
      });
  },
  async saveSalaryPayment({ commit, rootGetters, dispatch }, obj) {
    commit("loading/loading", true, { root: true });
    let paymentinfo = Object.assign(rootGetters["salaries/salaryForm"], obj);
    await axios
      .post(
        process.env.VUE_APP_API + "salary/saveSalaryPayment",
        paymentinfo,
        {
          headers: {
            Authorization: "Bearer " + rootGetters["auth/token"],
            "x-user-id": rootGetters["auth/user"].id,
          },
        }
      )
      .then((response) => {
        generalMethods.methods.executeAlert({
          icon: 'success',
          title: 'Success',
          text: response.data.message
        });
        commit("users/selectedSearchResult", {},{root:true});
        commit("clearSalaryForm");
      })
      .catch((error) => {
        dispatch('auth/handleErrors', error, { root: true });
      })
      .finally(() => {
        commit("loading/loading", false, { root: true });

      });
  },

  async salaryPaymentReport(
    { commit, rootGetters, dispatch },
    obj = { search: "", perPage: "", pageNumber: "1" }
  ) {
    commit("loading/loading", true, { root: true });
    await axios
      .get(process.env.VUE_APP_API + "salary/getSalaryPaymentReport", {
        params: {
          page: obj.pageNumber,
          perPage: obj.perPage,
          search: obj.search,
        },
        headers: {
          Authorization: "Bearer " + rootGetters["auth/token"],
          "x-user-id": rootGetters["auth/user"].id,
        },
      })
      .then((response) => {
        commit("setSalaryPaymentReport", response.data);
      })
      .catch((error) => {
        dispatch('auth/handleErrors', error, { root: true });

      })
      .finally(() => {
        commit("loading/loading", false, { root: true });

      });
  },
  async openPaymentDetails( { commit, rootGetters, dispatch },obj){
    commit("loading/loading", true, { root: true });
    await axios
      .get(process.env.VUE_APP_API + "salary/getSalaryPaymentDetails", {
        params: {
          salary_payment_id: obj.id,
        },
        headers: {
          Authorization: "Bearer " + rootGetters["auth/token"],
          "x-user-id": rootGetters["auth/user"].id,
        },
      })
      .then((response) => {
        commit("setSalaryPaymentDetails", {salaryPay:obj,details:response.data});
        router.push("/salaryPaymentDetails");
      })
      .catch((error) => {
        dispatch('auth/handleErrors', error, { root: true });

      })
      .finally(() => {
        commit("loading/loading", false, { root: true });

      });
  }
};
const mutations = {

  setSelectedSalary(state, item) {
    state.selectedSalary = item;
  },
  setSalaries(state, Salaries) {
    state.salaries = Salaries;
  },
  deleteSalary(state, id) {
    state.salaries.data = state.salaries.data.filter((salary) => salary.id !== id);
  },
  setSearchResults(state, suggestions) {
    state.searchResults = suggestions;
  },
  // setters
  setCurrentPage(state, page) {
    state.salaries.current_page = page;
  },
  setPerPage(state, perPage) {
    state.salaries.per_page = perPage;
  },
  selectedSearchResult(state, obj) {
    state.selectedSearchResult = obj;
  },
  setSalaryForm(state, obj) {
    state.salaryForm = Object.assign(state.salaryForm, obj);
  },
  changeStep(state, step) {
    state.salaryForm.e6 = step;
  },
  clearSalaryForm(state) {
    state.salaryForm = {
      e6: 1,
      selectedStaff:{first_name:null,middle_name:null,last_name:null},
      bonusesForPayment:[],
      fixedDeductionsForPayment:[],
      miscDeductionsForPayment:[],
      remark:null,
      signature:null,
    };
  },
  setSalaryPaymentReport(state, report) {
    state.salaryPaymentReport = report;
  },
  setSalaryPaymentDetails(state,details){
    state.salaryPaymentDetails=details;
  }

};

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations,
};
