import axios from "axios";
import router from "@/router";
import generalMethods from "@/mixins/generalMethods";

const state = {
  miscDeductions: {
    current_page: "",
    data: [],
    first_page_url: "",
    from: 0,
    last_page: 0,
    last_page_url: "",
    links: [{ url: "", label: "", active: false }],
    next_page_url: "",
    path: "",
    per_page: 0,
    prev_page_url: "",
    to: 0,
    total: 0,
  },
  searchResults: [],
  selectedSearchResult: {},
  miscDeductionsForPayment: []
};
const getters = {
  miscDeductionsForPayment(state) { return state.miscDeductionsForPayment; },
  miscDeductionsPerPage(state) {
    return state.miscDeductions.per_page;
  },
  allMiscDeductions(state) {
    return state.miscDeductions.data;
  },
  selectedSearchResult(state) {
    return state.selectedSearchResult;
  },

};
const actions = {

  async setMiscDeduction({ commit, rootGetters, dispatch }, obj) {
    commit("loading/loading", true, { root: true });
    await axios
      .post(
        process.env.VUE_APP_API + "miscDeduction/setMiscDeduction",
        obj.miscDeduction,
        {
          headers: {
            Authorization: "Bearer " + rootGetters["auth/token"],
            "x-user-id": rootGetters["auth/user"].id,
          },
        }
      )
      .then((response) => {
        dispatch("fetchItems");
        generalMethods.methods.executeAlert({
          icon: 'success',
          title: 'Success',
          text: response.data.message
        });
        obj.close();
      })
      .catch((error) => {
        dispatch('auth/handleErrors', error, { root: true });
      })
      .finally(() => {
        commit("loading/loading", false, { root: true });

      });
  },

  async fetchItems(
    { commit, rootGetters, dispatch },
    obj = { search: "", perPage: "", pageNumber: "1" }
  ) {
    commit("loading/loading", true, { root: true });
    await axios
      .get(process.env.VUE_APP_API + "miscDeduction/getMiscDeductions", {
        params: {
          user_id: rootGetters["salaries/selectedSalary"].user_id,
          page: obj.pageNumber,
          perPage: obj.perPage,
          search: obj.search,
        },
        headers: {
          Authorization: "Bearer " + rootGetters["auth/token"],
          "x-user-id": rootGetters["auth/user"].id,
        },
      })
      .then((response) => {
        commit("setMiscDeductions", response.data);
      })
      .catch((error) => {
        dispatch('auth/handleErrors', error, { root: true });

      })
      .finally(() => {
        commit("loading/loading", false, { root: true });

      });
  },
  async updateMiscDeduction({ commit, rootGetters, dispatch }, obj) {
    commit("loading/loading", true, { root: true });
    await axios
      .put(
        process.env.VUE_APP_API + "miscDeduction/updateMiscDeduction",
        obj.miscDeduction,
        {
          headers: {
            Authorization: "Bearer " + rootGetters["auth/token"],
            "x-user-id": rootGetters["auth/user"].id,
          },
        }
      )
      .then((response) => {
        dispatch("fetchItems");

        generalMethods.methods.executeAlert({
          icon: 'success',
          title: 'Success',
          text: response.data.message
        });
        obj.close();
      })
      .catch((error) => {
        dispatch('auth/handleErrors', error, { root: true });

      })
      .finally(() => {
        commit("loading/loading", false, { root: true });

      });
  },
  async updateMiscDeductionComplete({ commit, rootGetters, dispatch }, miscDeduction) {
    commit("loading/loading", true, { root: true });
    await axios
      .put(
        process.env.VUE_APP_API + "miscDeduction/updateMiscDeductionComplete",
        {
          id: miscDeduction.id,
        },
        {
          headers: {
            Authorization: "Bearer " + rootGetters["auth/token"],
            "x-user-id": rootGetters["auth/user"].id,
          },
        }
      )
      .then((response) => {
        dispatch("fetchItems");

        generalMethods.methods.executeAlert({
          icon: 'success',
          title: 'Success',
          text: response.data.message
        });
      })
      .catch((error) => {
        dispatch('auth/handleErrors', error, { root: true });

      })
      .finally(() => {
        commit("loading/loading", false, { root: true });

      });
  },
  async deleteMiscDeduction({ commit, rootGetters, dispatch }, obj) {
    commit("loading/loading", true, { root: true });
    await axios
      .delete(process.env.VUE_APP_API + "miscDeduction/deleteMiscDeduction/" + obj.id, {
        headers: {
          Authorization: "Bearer " + rootGetters["auth/token"],
          "x-user-id": rootGetters["auth/user"].id,
        },
      })
      .then((response) => {
        commit("deleteMiscDeduction", obj.id);
        generalMethods.methods.executeAlert({
          icon: 'success',
          title: 'Success',
          text: response.data.message
        });
        obj.close();
      })
      .catch((error) => {
        dispatch('auth/handleErrors', error, { root: true });
      })
      .finally(() => {
        commit("loading/loading", false, { root: true });

      });
  },
  async getMiscDeductionsForPayment(
    { commit, rootGetters, dispatch }
  ) {
    commit("loading/loading", true, { root: true });
    await axios
      .get(process.env.VUE_APP_API + "miscDeduction/getMiscDeductionsForPayment", {
        params: {
          user_id: rootGetters["salaries/salaryForm"].selectedStaff.id
        },
        headers: {
          Authorization: "Bearer " + rootGetters["auth/token"],
          "x-user-id": rootGetters["auth/user"].id,
        },
      })
      .then((response) => {
        commit("setMiscDeductionsForPayment", response.data);
      })
      .catch((error) => {
        dispatch('auth/handleErrors', error, { root: true });

      })
      .finally(() => {
        commit("loading/loading", false, { root: true });

      });
  },
  async reGetMiscDeductionsForPayment(
    { commit, rootGetters, dispatch }
  ) {
    commit("loading/loading", true, { root: true });
    await axios
      .get(process.env.VUE_APP_API + "miscDeduction/getMiscDeductionsForPayment", {
        params: {
          user_id: rootGetters["salaries/salaryForm"].selectedStaff.id
        },
        headers: {
          Authorization: "Bearer " + rootGetters["auth/token"],
          "x-user-id": rootGetters["auth/user"].id,
        },
      })
      .then((response) => {
        commit("resetMiscDeductionsForPayment", response.data);
      })
      .catch((error) => {
        dispatch('auth/handleErrors', error, { root: true });

      })
      .finally(() => {
        commit("loading/loading", false, { root: true });

      });
  },
};
const mutations = {
  setMiscDeductionsForPayment(state, payload) {
    state.miscDeductionsForPayment = payload;
  },
  setMiscDeductions(state, MiscDeductions) {
    state.miscDeductions = MiscDeductions;
  },
  deleteMiscDeduction(state, id) {
    state.miscDeductions.data = state.miscDeductions.data.filter((miscDeduction) => miscDeduction.id !== id);
  },
  setSearchResults(state, suggestions) {
    state.searchResults = suggestions;
  },
  // setters
  setCurrentPage(state, page) {
    state.miscDeductions.current_page = page;
  },
  setPerPage(state, perPage) {
    state.miscDeductions.per_page = perPage;
  },
  resetMiscDeductionsForPayment(state, payload) {
    const newItems = payload.filter(item => !state.miscDeductionsForPayment.some(existingItem => existingItem.id === item.id));
    state.miscDeductionsForPayment = state.miscDeductionsForPayment.concat(newItems);
  },
  deleteMiscDeduction(state, deduction) {
    state.miscDeductionsForPayment  = state.miscDeductionsForPayment.filter((miscDeductionsForPayment) => miscDeductionsForPayment.id !== deduction.id);
  }
};

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations,
};
