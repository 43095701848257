import axios from "axios";
import router from "@/router";
import generalMethods from "@/mixins/generalMethods";

const state = {
  patients: {
    current_page: "",
    data: [],
    first_page_url: "",
    from: 0,
    last_page: 0,
    last_page_url: "",
    links: [{ url: "", label: "", active: false }],
    next_page_url: "",
    path: "",
    per_page: 0,
    prev_page_url: "",
    to: 0,
    total: 0,
  },
  searchResults: [],
  selectedSearchResult: {},
};
const getters = {
  patientsPerPage(state) {
    return state.patients.per_page;
  },
  allPatients(state) {
    return state.patients.data;
  },
  selectedSearchResult(state) {
    return state.selectedSearchResult;
  }
};
const actions = {
  async setPatient({ commit, rootGetters, dispatch }, obj) {
    let formData = new FormData();
    // Append patient data to FormData dynamically
    for (const key in obj.patient) {
      if (obj.patient.hasOwnProperty(key)) {
        formData.append(key, obj.patient[key]);
      }
    }

    const selectedCreditCompanyResult = rootGetters["creditCompanies/selectedSearchResult"];
    const selectedPatientCategoryResult = rootGetters["patientCategories/selectedSearchResult"];
    const patient_category_id = selectedPatientCategoryResult && "id" in selectedPatientCategoryResult ? selectedPatientCategoryResult.id : '';
    const credit_company_id = selectedCreditCompanyResult && "id" in selectedCreditCompanyResult ? selectedCreditCompanyResult.id : '';

    formData.append("patient_category_id", patient_category_id);
    formData.append("credit_company_id", credit_company_id);

    commit("loading/loading", true, { root: true });
    await axios
      .post(
        process.env.VUE_APP_API + "patient/setPatient",
        formData,
        {
          headers: {
            Authorization: "Bearer " + rootGetters["auth/token"],
            "x-user-id": rootGetters["auth/user"].id,
          },
        }
      )
      .then((response) => {
        generalMethods.methods.executeAlert({
          icon: 'success',
          title: 'Success',
          text: response.data.message
        });
        obj.close();
        dispatch("fetchItems");
      })
      .catch((error) => {
        dispatch('auth/handleErrors', error, { root: true });
      })
      .finally(() => {
        commit("loading/loading", false, { root: true });

      });
  },
  async fetchItems(
    { commit, rootGetters, dispatch },
    obj = { search: "", perPage: "", pageNumber: "1" }
  ) {
    commit("loading/loading", true, { root: true });
    await axios
      .get(process.env.VUE_APP_API + "patient/getPatients", {
        params: {
          page: obj.pageNumber,
          perPage: obj.perPage,
          search: obj.search,
        },
        headers: {
          Authorization: "Bearer " + rootGetters["auth/token"],
          "x-user-id": rootGetters["auth/user"].id,
        },
      })
      .then((response) => {
        commit("setPatients", response.data);
      })
      .catch((error) => {
        dispatch('auth/handleErrors', error, { root: true });

      })
      .finally(() => {
        commit("loading/loading", false, { root: true });

      });
  },
  async updatePatient({ commit, rootGetters, dispatch }, obj) {
    commit("loading/loading", true, { root: true });
    let formData = new FormData();

    // Append patient data to FormData dynamically
    for (const key in obj.patient) {
      if (obj.patient.hasOwnProperty(key)) {
        formData.append(key, obj.patient[key]);
      }
    }

    const selectedCreditCompanyResult = rootGetters["creditCompanies/selectedSearchResult"];
    const selectedPatientCategoryResult = rootGetters["patientCategories/selectedSearchResult"];
    const patient_category_id = selectedPatientCategoryResult && "id" in selectedPatientCategoryResult ? selectedPatientCategoryResult.id : '';
    const credit_company_id = selectedCreditCompanyResult && "id" in selectedCreditCompanyResult ? selectedCreditCompanyResult.id : '';

    formData.append("patient_category_id", patient_category_id);
    formData.append("credit_company_id", credit_company_id);

    await axios
      .post(
        process.env.VUE_APP_API + "patient/updatePatient",
        formData,
        {
          headers: {
            Authorization: "Bearer " + rootGetters["auth/token"],
            "x-user-id": rootGetters["auth/user"].id,
          },
        }
      )
      .then((response) => {

        generalMethods.methods.executeAlert({
          icon: 'success',
          title: 'Success',
          text: response.data.message
        });
        obj.close();
        dispatch("fetchItems");

      })
      .catch((error) => {
        dispatch('auth/handleErrors', error, { root: true });

      })
      .finally(() => {
        commit("loading/loading", false, { root: true });

      });
  },
  async deletePatient({ commit, rootGetters, dispatch }, obj) {
    commit("loading/loading", true, { root: true });
    await axios
      .delete(process.env.VUE_APP_API + "patient/deletePatient/" + obj.id, {
        headers: {
          Authorization: "Bearer " + rootGetters["auth/token"],
          "x-user-id": rootGetters["auth/user"].id,
        },
      })
      .then((response) => {
        commit("deletePatient", obj.id);
        generalMethods.methods.executeAlert({
          icon: 'success',
          title: 'Success',
          text: response.data.message
        });
        obj.close();
      })
      .catch((error) => {
        dispatch('auth/handleErrors', error, { root: true });
      })
      .finally(() => {
        commit("loading/loading", false, { root: true });

      });
  },
  async search({ commit, rootGetters, dispatch }, query) {
    commit("loading/loading2", true, { root: true });
    await axios
      .get(process.env.VUE_APP_API + "patient/search", {
        params: {
          query: query,
        },
        headers: {
          Authorization: "Bearer " + rootGetters["auth/token"],
          "x-user-id": rootGetters["auth/user"].id,
        },
      })
      .then((response) => {
        commit("setSearchResults", response.data);
      })
      .catch((error) => {
        dispatch('auth/handleErrors', error, { root: true });
      })
      .finally(() => {
        commit("loading/loading2", false, { root: true });
      });
  },
};
const mutations = {
  setPatients(state, Patients) {
    state.patients = Patients;
  },
  deletePatient(state, id) {
    state.patients.data = state.patients.data.filter((patient) => patient.id !== id);
  },
  setSearchResults(state, suggestions) {
    state.searchResults = suggestions;
  },
  // setters
  setCurrentPage(state, page) {
    state.patients.current_page = page;
  },
  setPerPage(state, perPage) {
    state.patients.per_page = perPage;
  },
  selectedSearchResult(state, obj) {
    state.selectedSearchResult = obj;
  },
};

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations,
};
