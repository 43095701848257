<template>
  <div>
    <v-app-bar app fixed class="white">
      <v-toolbar flat color="transparent">
        <v-app-bar-nav-icon
          @click.stop="drawer2 = !drawer2"
          v-if="$vuetify.breakpoint.mobile"
        ></v-app-bar-nav-icon>
        <v-toolbar-title>{{
          capitalizeFirstLetter($route.meta.pageTitle)
        }}</v-toolbar-title>
      </v-toolbar>
    </v-app-bar>

    <!-- desktop -->
    <div v-if="!$vuetify.breakpoint.mobile">
      <v-navigation-drawer app left permanent v-model="drawer">
        <v-list>
          <v-img
            src="@/assets/logo-transparent.png"
            lazy-src="@/assets/logo-transparent.png"
            width="150px"
            class="mx-auto"
          >
            <template v-slot:placeholder>
              <v-row class="fill-height ma-0" align="center" justify="center">
                <v-progress-circular indeterminate color="#56BBD8"></v-progress-circular>
              </v-row>
            </template>
          </v-img>

          <v-list-item link>
            <v-list-item-content>
              <v-list-item-title class="text-h6">
                {{ userName }}
              </v-list-item-title>
              <!-- <v-list-item-subtitle>{{ userRole }}</v-list-item-subtitle> -->
            </v-list-item-content>
          </v-list-item>
        </v-list>
        <v-divider></v-divider>

        <v-list dense nav>
          <v-list-item-group active-class="border" color="red">
            <div v-for="(item, index) in items" :key="index" :to="item.link">
              <v-list-item v-if="item.link">
                <v-list-item-icon>
                  <v-icon small>{{ item.icon }}</v-icon>
                </v-list-item-icon>

                <v-list-item-content>
                  <v-list-item-title>{{ item.title }}</v-list-item-title>
                </v-list-item-content>
              </v-list-item>
              <v-list-group v-else :prepend-icon="item.icon">
                <template v-slot:activator>
                  <v-list-item-title>{{ item.title }}</v-list-item-title>
                </template>
                <v-list-item v-for="(sub, i) in item.submenu" :key="i" :to="sub.link">
                  <v-list-item-icon>
                    <v-icon small>{{ sub.icon }}</v-icon>
                  </v-list-item-icon>
                  <v-list-item-title>{{ sub.title }}</v-list-item-title>
                </v-list-item>
              </v-list-group>
            </div>
          </v-list-item-group>
        </v-list>
        <!-- signout  -->
        <template v-slot:append>
          <div class="pa-2">
            <v-btn block @click="signout()" color="#56BBD8" class="white--text">
              Logout
              <v-icon small right>mdi mdi-logout</v-icon>
            </v-btn>
          </div>
        </template>
      </v-navigation-drawer>
    </div>
    <!-- mobile -->
    <div v-if="$vuetify.breakpoint.mobile">
      <v-navigation-drawer app left v-model="drawer2">
        <v-img
          src="@/assets/logo-transparent.png"
          lazy-src="@/assets/logo-transparent.png"
          width="150px"
          class="mx-auto"
        >
          <template v-slot:placeholder>
            <v-row class="fill-height ma-0" align="center" justify="center">
              <v-progress-circular indeterminate color="#56BBD8"></v-progress-circular>
            </v-row>
          </template>
        </v-img>

        <v-list-item link>
          <v-list-item-content>
            <v-list-item-title class="text-h6">
              {{ userName }}
            </v-list-item-title>
            <!-- <v-list-item-subtitle>{{ userRole }}</v-list-item-subtitle> -->
          </v-list-item-content>
        </v-list-item>

        <v-divider></v-divider>

        <v-list dense nav>
          <v-list-item-group active-class="border" color="red">
            <div v-for="(item, index) in items" :key="index" :to="item.link">
              <v-list-item v-if="item.link">
                <v-list-item-icon>
                  <v-icon small>{{ item.icon }}</v-icon>
                </v-list-item-icon>

                <v-list-item-content>
                  <v-list-item-title>{{ item.title }}</v-list-item-title>
                </v-list-item-content>
              </v-list-item>
              <v-list-group v-else :prepend-icon="item.icon">
                <template v-slot:activator>
                  <v-list-item-title>{{ item.title }}</v-list-item-title>
                </template>
                <v-list-item v-for="(sub, i) in item.submenu" :key="i" :to="sub.link">
                  <v-list-item-icon>
                    <v-icon small>{{ sub.icon }}</v-icon>
                  </v-list-item-icon>
                  <v-list-item-title>{{ sub.title }}</v-list-item-title>
                </v-list-item>
              </v-list-group>
            </div>
          </v-list-item-group>
        </v-list>
        <!-- signout  -->
        <template v-slot:append>
          <div class="pa-2">
            <v-btn block @click="signout()" color="#56BBD8" class="white--text">
              Logout
              <v-icon rigth small>mdi mdi-logout</v-icon>
            </v-btn>
          </div>
        </template>
      </v-navigation-drawer>
    </div>
  </div>
</template>

<script>
import { mapGetters, mapActions } from "vuex";
export default {
  data: () => ({
    drawer: "true",
    drawer2: "false",
  }),
  computed: {
    ...mapGetters({ userName: "auth/userName" }),

    items() {
      return [
        {
          title: "Main Settings",
          icon: "mdi mdi-cog",
          submenu: [
            {
              title: "Credit companies",
              icon: "mdi mdi-office-building",
              link: "/creditCompanies",
            },
            {
              title: "Patient Categories",
              icon: "mdi mdi-group",
              link: "/patientCategories",
            },
            {
              title: "Roles",
              icon: "mdi mdi-account-switch",
              link: "/roles",
            },
            {
              title: "Staff",
              icon: "mdi mdi-account-multiple-plus-outline",
              link: "/users",
            },
            {
              title: "Fixed Salary Deductions",
              icon: "mdi mdi-finance",
              link: "/fixedDeductions",
            },
            {
              title: "Salaries",
              icon: "mdi mdi-account-multiple-check-outline",
              link: "/salaries",
            },
          ],
        },
        {
          title: "Reception",
          icon: "mdi mdi-desk",
          submenu: [
            {
              title: "Patients",
              icon: "mdi mdi-account-group",
              link: "/patients",
            },
            {
              title: "Appoinments",
              icon: "mdi mdi-calendar-range",
              link: "/appointments",
            },
          ],
        },
        {
          title: "Transactions",
          icon: "mdi mdi-cash-multiple",
          submenu: [
            {
              title: "Salary Payment",
              icon: "mdi mdi-hand-coin",
              link: "/salaryPayment",
            },
            {
              title: "Sales",
              icon: "mdi mdi-finance",
              link: "/sales",
            },
            {
              title: "Purchases",
              icon: "mdi mdi-finance",
              link: "/purchases",
            },
            {
              title: "Expenses",
              icon: "mdi mdi-finance",
              link: "/expenses",
            },
          ],
        },
        {
          title: "Reports",
          icon: "mdi mdi-chart-bar",
          submenu: [
            {
              title: "Salary Payment Report",
              icon: "mdi mdi-cash-multiple",
              link: "/salaryPaymentReport",
            },
            {
              title: "Purchases Report",
              icon: "mdi mdi-cash-multiple",
              link: "/purchasesReport",
            },
            {
              title: "Daily Report",
              icon: "mdi mdi-cash-multiple",
              link: "/purchasesReport",
            },
            {
              title: "Expenses Report",
              icon: "mdi mdi-cash-multiple",
              link: "/exepenses_report",
            },
          ],
        },
        // {
        //   title: "My Profile",
        //   icon: "mdi mdi-account-circle",
        //   link: "/profile",
        // },
      ];
    },
  },
  methods: {
    ...mapActions({ signout: "auth/signout" }),
    capitalizeFirstLetter(str) {
      if (!str || typeof str !== "string") {
        return "";
      }
      return str.charAt(0).toUpperCase() + str.slice(1);
    },
  },
};
</script>
<style scoped>
.list-item-custom {
  height: auto;
}

.avatar-custom {
  width: auto;
  height: auto;
}

.border {
  border: 2px dashed #56bbd8;
}
</style>
