import Vue from "vue";
import Vuex from "vuex";
import auth from "./modules/auth";
import users from "./modules/users";
import roles from "./modules/roles";
import deductions from "./modules/deductions";
import salaries from "./modules/salaries";
import miscDeductions from "./modules/miscDeductions";
import bonuses from "./modules/bonuses";
import patientCategories from "./modules/patientCategories";
import creditCompanies from "./modules/creditCompanies";
import patients from "./modules/patients";
import appointments from "./modules/appointments";
import loading from "./modules/loading";


import createPersistedState from "vuex-persistedstate";
Vue.use(Vuex);

export default new Vuex.Store({
  //storing session state incase user refreshes page user token is not lost
  plugins: [
    createPersistedState({
      storage: window.localStorage,
    }),
  ],
  modules: {
    auth,
    loading,
    users,
    roles,
    deductions,
    salaries,
    miscDeductions,
    bonuses,
    patientCategories,
    creditCompanies,
    patients,
    appointments,
  },
});
