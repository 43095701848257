import Vue from 'vue'
import VueSweetalert2 from 'vue-sweetalert2';
import 'sweetalert2/dist/sweetalert2.min.css';

Vue.use(VueSweetalert2);


const generalMethods = {
  methods: {
    capitalizeWords(str) {
      return str.replace(/\b[a-z]/g, (match) => match.toUpperCase());
    },
    formatNumbersWithCommas(number) {
      const parsedNumber = parseFloat(number); // Convert the string to a number
      if (isNaN(parsedNumber)) {
        return number; // Return the input if it's not a valid number
      }
      return parsedNumber.toLocaleString(undefined, {
        maximumFractionDigits: 2,
      });
    },
    formatFriendlyDate(dateString) {
      const options = {
        year: "numeric",
        month: "short",
        day: "numeric",
        hour: "numeric",
        minute: "numeric",
      };

      const parsedDate = new Date(dateString);
      const formattedDate = parsedDate.toLocaleDateString("en-US", options);
      return formattedDate;
    },
    executeAlert({ icon = '', title = '', text = '' }) {
      Vue.swal({
        icon: icon,
        title: title,
        text: text,
        toast: true,
        position: "top-end",
        showConfirmButton: false,
        timer: 3000,
        timerProgressBar: true,
        didOpen: (toast) => {
          toast.onmouseenter = Vue.swal.stopTimer;
          toast.onmouseleave = Vue.swal.resumeTimer;
        }
      });
    },
    executeSimpleAlert({title = '', text = '',}){
      Vue.swal(
        {
          icon: "success",
          title: title,
          html: text,
          confirmButtonText: "ok",
          confirmButtonColor: "#2B91F3",
        }
      );
    },
    executeAlertWithMethod({ icon = '', title = '', text = '', execute = '' }) {
      Vue.swal({
        icon: icon,
        title: title,
        html: text,
        showCancelButton: true,
        confirmButtonText: "Delete",
        confirmButtonColor: "#56BBD8",
      }).then((result) => {
        /* Read more about isConfirmed, isDenied below */
        if (result.isConfirmed) {
          if (execute) {
            execute();
          }
        }
         
      });
    },
  },
};
export default generalMethods;
