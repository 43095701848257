import Vue from 'vue'
import VueRouter from 'vue-router'
import signin from "../views/signin.vue";
import store from "@/store";
import generalMethods from "@/mixins/generalMethods";


Vue.use(VueRouter)

const routes = [
  {
    path: "/",
    name: "signin",
    component: signin,
    meta: {
      requiresAuth: false,
      pageTitle: "signin",
    },
  },
  {
    path: "/roles",
    name: "roles",
    component: () =>
      import(/* webpackChunkName: "settings" */ "../views/roles.vue"),
    meta: {
      requiresAuth: true,
      pageTitle: "roles",
    },
  },
  {
    path: "/patientCategories",
    name: "patientCategories",
    component: () =>
      import(/* webpackChunkName: "settings" */ "../views/patientCategories.vue"),
    meta: {
      requiresAuth: true,
      pageTitle: "patient categories",
    },
  },  {
    path: "/creditCompanies",
    name: "creditCompanies",
    component: () =>
      import(/* webpackChunkName: "settings" */ "../views/creditCompanies.vue"),
    meta: {
      requiresAuth: true,
      pageTitle: "credit companies",
    },
  },
  {
    path: "/users",
    name: "staff",
    component: () =>
      import(/* webpackChunkName: "settings" */ "../views/users.vue"),
    meta: {
      requiresAuth: true,
      pageTitle: "staff",
    },
  },
  {
    path: "/fixedDeductions",
    name: "fixeddeductions",
    component: () =>
      import(/* webpackChunkName: "settings" */ "../views/fixedDeduction.vue"),
    meta: {
      requiresAuth: true,
      pageTitle: "fixed Salary Deductions",
    },
  },
  {
    path: "/salaries",
    name: "salaries",
    component: () =>
      import(/* webpackChunkName: "settings" */ "../views/salaries.vue"),
    meta: {
      requiresAuth: true,
      pageTitle: "Staff Salaries",

    },
  },
  {
    path: "/miscDeductions",
    name: "miscdeductions",
    component: () =>
      import(/* webpackChunkName: "settings" */ "../views/miscDeductions.vue"),
    meta: {
      requiresAuth: true,
      pageTitle: "Miscellaneous Deductions"
    },
  },
  {
    path: "/bonus",
    name: "bonus",
    component: () =>
      import(/* webpackChunkName: "settings" */ "../views/bonus.vue"),
    meta: {
      requiresAuth: true,
      pageTitle: "salary bonus",

    },
  },
  {
    path: "/salaryPayment",
    name: "salarypayment",
    component: () =>
      import(/* webpackChunkName: "transactions" */ "../views/salaryPayment.vue"),
    meta: {
      requiresAuth: true,
      pageTitle: "salary payment",
    },
  },
  {
    path: "/salaryPaymentReport",
    name: "salarypaymentreport",
    component: () =>
      import(/* webpackChunkName: "reports" */ "../views/salaryPaymentReport.vue"),
    meta: {
      requiresAuth: true,
      pageTitle: "Salary Payment Report",
    },
  },
  {
    path: "/salaryPaymentDetails",
    name: "salarypaymentdetails",
    component: () =>
      import(/* webpackChunkName: "reports" */ "../views/salaryPaymentDetails.vue"),
    meta: {
      requiresAuth: true,
      pageTitle: "Salary Payment Details",
    },
  },
  {
    path: "/patients",
    name: "patients",
    component: () =>
      import(/* webpackChunkName: "reception" */ "../views/patients.vue"),
    meta: {
      requiresAuth: true,
      pageTitle: "Patients",
    },
  },
  {
    path: "/appointments",
    name: "appointments",
    component: () =>
      import(/* webpackChunkName: "reception" */ "../views/appointments.vue"),
    meta: {
      requiresAuth: true,
      pageTitle: "Patient Appointments",
    },
  },
  {
    path: "/not-found",
    name: "not-found",
    component: () =>
      import(/* webpackChunkName: "notfound" */ "../views/notFound.vue"),
    meta: {
      requiresAuth: true,
      pageTitle: "Uknown Page",
    },
  },
]

const router = new VueRouter({
  routes
});

// Define the handleBackButton function
const handleBackButton = () => {
  router.beforeEach((to, from, next) => {
    if (window.event.type === 'popstate' && from.name === 'signin') {
      next(false);
    } else {
      next();
    }
  });
};
router.beforeEach((to, from, next) => {
  // Check if the route path exists in the route configuration
  const isValidRoute = routes.some(route => route.path === to.path);

  if (!isValidRoute) {
    // Throw an error or handle the case where the route path doesn't exist
    console.error(`Error: Route path '${to.path}' does not exist.`);
    // Alternatively, you can redirect the user to an error page
    next({ name: 'not-found' });
  } else if (to.matched.some(record => record.meta.requiresAuth)) {
    if (store.getters["auth/authenticated"]) {
      next();
    } else {
      next({ name: "signin" });
      generalMethods.methods.executeAlert({
        icon: 'error',
        title: 'Error',
        text: "You need to be authenticated."
      });
    }
  } else {
    if (store.getters["auth/authenticated"] && (to.name === 'signin' || to.name === 'signup')) {
      next({ path: "/users" });
      generalMethods.methods.executeAlert({
        icon: 'info',
        title: 'Info',
        text: "You are already authenticated.",
      });
    } else {
      next();
    }
  }
});


export default router
